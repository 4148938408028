import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center text-[.24rem] gap-x-[.2rem] px-[.2rem] mb-[.2rem] bg-[#fff] py-[.2rem]" }
const _hoisted_2 = { class: "w-[.6rem]" }
const _hoisted_3 = { class: "w-[1.6rem] h-[1.6rem] flex items-center justify-center" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "flex-auto flex flex-col gap-y-[.1rem]" }
const _hoisted_6 = { class: "text-[.36rem] font-bold" }
const _hoisted_7 = { class: "flex bg-[#eee] p-[.1rem]" }
const _hoisted_8 = { class: "text-[#666]" }
const _hoisted_9 = { class: "flex justify-end w-full" }

import { ref, reactive, watch } from 'vue'
import { CheckboxGroup, Checkbox, Stepper } from 'vant'

export default /*@__PURE__*/_defineComponent({
  __name: 'cartItem',
  props: {
  id: Number,
  stock: Number,
  quantity: Number,
  itemName: String,
  skuName: String,
  effectImage: {
    type: Array,
    default() {
      return []
    }
  }
},
  emits: ['changeChecked', 'change'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
const checked = ref(['a'])
const value = ref(props.stock || 1)

watch(() => checked.value, () => {
  emits('changeChecked', props.id, checked.value)
})

watch(() => props.stock, (val) => {
  value.value = val || 1
})

const onStepChange = () => {
  emits('change', props.id, {
    quantity: value.value
  })
}
__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(Checkbox), { name: __props.id }, null, 8, ["name"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", {
        src: __props.effectImage?.[0],
        alt: "",
        class: "max-w-full max-h-full"
      }, null, 8, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("p", _hoisted_6, _toDisplayString(__props.itemName), 1),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("p", _hoisted_8, _toDisplayString(__props.skuName), 1),
        _cache[1] || (_cache[1] = _createElementVNode("i", { class: "" }, null, -1))
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_unref(Stepper), {
          modelValue: value.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
          max: 100,
          min: 1,
          onChange: onStepChange
        }, null, 8, ["modelValue"])
      ])
    ])
  ]))
}
}

})